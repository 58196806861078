import React from 'react'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'

export default function SectionError(props: {
  message?: string
  className?: string
}) {
  const _message = props.message || 'Sorry, we are unable to load this section.'
  return (
    <EmptyState
      className={props.className}
      testId={'section-error'}
      icon={<WarningOutlineIcon />}
    >
      {_message} <br /> Please{' '}
      <a
        className='text-link'
        href={encodeURI(window.location.href)}
        onClick={() => window.location.reload()}
      >
        reload the page
      </a>
      .
      <br />
      Still not working?{' '}
      <a className='text-link' href='mailto:supply@toasttab.com'>
        Contact us
      </a>
      .
    </EmptyState>
  )
}
