import React, { ReactNode, useEffect, useState } from 'react'
import defaultStringsEnUS from './locales/en-US.json'
import {
  addResources,
  hasResourceBundle,
  customTranslationMethods
} from '@toasttab/buffet-pui-translation-utilities'
import { getLocale } from '@toasttab/buffet-pui-locale-utilities'

const defaultStrings = defaultStringsEnUS
export type AppStrings = Record<keyof typeof defaultStrings, string>

const spaName = 'invoice-pay-web'

export const { t, Trans } = customTranslationMethods<AppStrings>(spaName)

export async function loadTranslations() {
  const locale = getLocale()
  if (!hasResourceBundle(locale, spaName)) {
    addResources('en-US', spaName, defaultStrings)
    try {
      const bundle = await import(`./locales/${locale}.json`)
      addResources(locale, spaName, bundle.default)
    } catch {
      addResources(locale, spaName, defaultStrings)
    }
  }
}

interface DecoratorContext {
  globals: {
    theme?: string
    backgrounds?: {
      value?: string
    }
    locale: string
  }
  canvasElement?: HTMLElement
}

type Decorator = (
  Story: React.ComponentType,
  context: DecoratorContext
) => ReactNode

export const LoadTranslationsDecorator: Decorator = (Story, context) => {
  const [_, setTranslationsLoaded] = useState(false)

  useEffect(() => {
    setTranslationsLoaded(false)
    loadTranslations().then(() => {
      setTranslationsLoaded(true)
    })
  }, [context.globals.locale])

  return <Story />
}
