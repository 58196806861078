import React from 'react'
import { BodyBackgroundColor } from '../BodyBackgroundColor'
import classNames from 'classnames'
import { useWindowSize } from 'usehooks-ts'

export interface ThemedLayoutProps {
  logoImageUrl?: string
  name?: string
  headerImageUrl?: string
  subheader?: React.ReactNode
  isDiscussionOpen?: boolean
}

export const ThemedLayout: React.FC<
  React.PropsWithChildren<ThemedLayoutProps>
> = ({
  logoImageUrl,
  name,
  headerImageUrl,
  subheader,
  isDiscussionOpen,
  children
}) => {
  const { width } = useWindowSize()
  const isMobileDiscussionOpen = isDiscussionOpen && width < 1242

  return (
    <div className={'w-full flex flex-col gap-6 h-400'}>
      {!isMobileDiscussionOpen && (
        <>
          <BodyBackgroundColor colorVar={'ds-surface-default'} />
          <header
            className={
              'min-h-[20px] md:min-h-[60px] flex flex-col justify-center'
            }
          >
            {logoImageUrl && (
              <div>
                <img
                  className={
                    'block max-h-[48px] max-w-[140px] md:max-h-[120px] md:max-w-[220px] transition-[max-width,max-height]'
                  }
                  src={logoImageUrl}
                  alt={name}
                />
              </div>
            )}
            {subheader && <div className={'max-w-6xl'}>{subheader}</div>}
          </header>{' '}
        </>
      )}
      <div
        className={classNames(
          'w-full flex flex-col gap-6 self-center',
          !isDiscussionOpen && 'max-w-6xl'
        )}
      >
        {headerImageUrl && !isMobileDiscussionOpen && (
          <div
            className='h-56 md:h-80 transition-[height,margin] bg-cover bg-center md:rounded-md -mx-8 md:mx-0'
            style={{
              backgroundImage: `url("${headerImageUrl}")`
            }}
          ></div>
        )}
        <div className={'flex flex-col md:flex-row gap-y-4 md:gap-y-6'}>
          {children}
        </div>
      </div>
    </div>
  )
}
