import { App } from './app/App'
import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'

import { loadTranslations } from '@local/translations'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'nvs-inv-pay',
  rootComponent: App,

  sentry: {
    publicKey: '84f6403cac364a9db9645b41cec8a45e@o37442',
    projectId: '6071217',
    releaseVersion: process.env.PKG_VERSION
  },

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

// @ts-ignore must have a [Symbol.iterator]() method that returns an iterator.
export const bootstrap = [loadTranslations, ...reactLifecycles.bootstrap]
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
