import React from 'react'
import classNames from 'classnames'

interface ThemedLayoutSideContentProps {
  className?: string
}

export const ThemedLayoutSideContent: React.FC<
  React.PropsWithChildren<ThemedLayoutSideContentProps>
> = ({ className, children }) => {
  return (
    <div
      className={classNames(
        'flex-col gap-6 gap-y-4 md:gap-y-6 order-first md:order-1',
        className
      )}
    >
      {children}
    </div>
  )
}
